// @ts-nocheck

import {
  Vue, Options
} from 'vue-class-component'
import * as bootstrap from 'bootstrap'
import router from '@/router/index'
import Header from '@/components/Header.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import CurrencyInputDisable from '@/components/CurrencyInputDisable.vue'
import swal from 'sweetalert'
import Utils from '@/utils/utils'
import { customerFactory } from '@/http/customer'
import { masterDataFactory } from '@/http/master-data'
import { usFactory } from '@/http/uang-sekolah'
import constants from '@/constants/constants'
import token from '@/constants/token'
import { setupFactory } from '@/http/setup'

declare var snap: any

@Options({
  components: {
    Header, CurrencyInput, CurrencyInputDisable
  },
  data() {
    return {
      currentSort:'name',
      currentSortDir:'asc',
      userPayments: [],
      userBills: [],
      userOrders: [],
      stats: {
        total_bill: 0,
        total_paid: 0,
        allow_mass_checkout: true
      },
      user: {
        name: '',
        class_name: '',
        parents_father: '',
        parents_mother: '',
        user_profile_no_hp: '',
        student_profile_nis: ''
      },
      school: {
        name: ''
      },
      statuses: [],
      schoolYears: [],
      months: [],
      convenienceFee: [],
      data_master: {
        school_years: "1",
        school: "1",
        months: "1",
        convenience_fee: "1"
      },
      param: {
        school_year_id: 0,
        order_at: 'deadline_date'
      },
      school_year_name: '',
      data: {
        bill_name: '',
        school_year_name: '',
        month_name: '',
        fine_rules: {
          value: 0,
          type: 'nominal',
          formula: 'flat',
          late_month_fine: {
            value: 0,
            type: 'nominal',
            formula: 'flat'
          }
        },
        total_amount: 0,
        total_payment: 0,
        late_charge: 0,
        details: [],
        deadline_date: '',
        due_date: '',
        description: '',
        last_date: ''
      },
      totalAmount: 0,
      totalSelecttedAmount: 0,
      totalPaid: 0,
      billCount: 0,
      billIds: [],
      allSelectedBill: false,
      path: process.env.VUE_APP_STORAGE_PATH,
      instance: '',
      subdomain: token.user.subdomain,
      token_order_id: '',
      constants: constants,
      loadingBulk: false,
      loading: [],
      loadingYear: false,
      setting: [],
      convenience_id: [],
      paymentMethod: true,
      payment: {
        amount: 0,
        convenience_fee: 0,
        merchant_fee: 0,
        total_discount: 0,
        ppn: 0,
        total_amount: 0
      },
      tempCheckout: {
        ids: [],
        type: ''
      },
      xenditModal: null,
      bcaModal: null,
      bca: {
        bank_name: '',
        orderId: '',
        va_number: '',
        va_number_nobill: '',
        amount: 0,
        convenience_fee: 0,
        merchant_fee: 0,
        total_discount: 0,
        ppn: 0,
        total_payment: 0
      },
      xenditInvoice: "",
      discountSetting: "",
      imagepath: null,
      repayUserBillIDs: null,
      disableCheckboxes: 'false',
      useImportVA: false,
      sppPromoAnnuallyPaymentPercentage: 0,
      imported_order: null,
      imported_us_order: null,
      promoTerm: "",
      totalLateCharge: 0,
    }
  },
  methods: {
    inArray: function(id:any) {
      if (this.billIds.length > 0 && id != '') {
        var exist = this.billIds.indexOf(id)
        if (exist > -1) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    tableDataClass(due_date:any, deadline_date:any, status: any) {
      var duedate = new Date(due_date)
      var datenow = new Date()
      datenow.setHours(0,0,0,0)
      var deadlinedate = new Date(deadline_date)
      var d1 = deadlinedate.getTime()
      var d2 = datenow.getTime()
      var d3 = duedate.getTime()
      var normal, almostExpired, expired = false
      if (status == constants.BILL_PENDING || status == constants.BILL_UNPAID) {
        if (d2 >= d1) {
          expired = true
        } else if (d2 > d3 && d3 < d1) {
          almostExpired = true
        } else if (d2 < d3) {
          normal = true
        }
      } else {
        normal = true
      }
      return {
        '': normal,
        'table-warning': almostExpired,
        'table-danger': expired,
      }
    },
    onImageLoadFailure: function(event:any) {
      event.target.src = '/img/photos/default-avatar.png'
    },
    checkAllBill: function() {
      this.billIds = []
      if (!this.allSelectedBill) {
          this.userBills.forEach((detail:any) => {
            if ((detail.status == constants.BILL_PENDING || detail.status == constants.BILL_UNPAID) && detail.spp_order_ids == undefined || this.isCanceled(detail.spp_order_ids)) {
              this.billIds.push(detail.id)
            }
          })
      }
    },
    checkAllBillBySetting: function() {
      this.billIds = []
      if (!this.allSelectedBill) {
          this.userBills.forEach((detail:any) => {
            if ((detail.status == constants.BILL_PENDING || detail.status == constants.BILL_UNPAID) && detail.spp_order_ids == undefined || this.isCanceled(detail.spp_order_ids)) {
              if (detail.discount_setting_id === this.setting['UANGSEKOLAH_DISCOUNT_SETTING']) {
                this.billIds.push(detail.id)
              }
            }
          })
      }
    },
    sort:function(s:any) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc'
      }
      this.currentSort = s
    },
    getMonth: function(date:any) {
      if (date != '' && date != undefined) {
        const d = date.split("-")
        const month  = d[2]
        return this.months[month].id
      } else {
        return ''
      }
    },
    beautifyDate: function(date:any) {
      return Utils.beautifyDate(date)
    },
    toLocaleString: function(val:any) {
      return Utils.toLocaleString(val)
    },
    isCanceled: function(spp_order_ids:any) {
      if (!spp_order_ids) {
        return true;
      }

      if(spp_order_ids.length == 1) {
        var result = this.userOrders.find((x:any) => x.id === spp_order_ids[0])
        if (!result) {
          return false;
        }

        if (result.status == constants.ORDER_CANCELED || result.status == constants.ORDER_EXPIRED) {
          return true
        } else {
          return false
        }
      } else {
        let status = false;
        spp_order_ids.forEach((id) => {
          let result = this.userOrders.find((x:any) => x.id === id);
          if (result && (result.status == constants.ORDER_CANCELED || result.status == constants.ORDER_EXPIRED)) {
            status = true;
          }
        });
      }

    },
    detailBill: function(id:any) {
      Promise.all([
        customerFactory.detailBill(id)
      ]).then(results => {
        if (results[0].error == false) {
          var result = results[0].data.bill_user
          var ppn = result.ppn ? result.ppn : 0
          var deadline_date = new Date(result.deadline_date);
          var y = deadline_date.getFullYear();
          var m = deadline_date.getMonth();
          var last_date = new Date(y, m + 1, 0);
          var last_date_str = Utils.beautifyDate(y + '-' + (m + 1) + '-' + last_date.getDate() + ' 23:59:59');
          var data = {
            school_year_name: this.school_year_name,
            bill_name: result.bill.name,
            month_name: this.getMonth(result.id),
            deadline_date: Utils.beautifyDate(result.deadline_date),
            due_date: Utils.beautifyDate(result.due_date),
            total_amount: result.total_amount,
            total_payment: result.total_amount + result.late_charge + ppn,
            late_charge: result.late_charge,
            ppn: ppn,
            description: result.bill.description,
            fine_rules: result.bill.fine_rules,
            details: result.details,
            last_date: last_date_str
          }

          // Remove bill detail which has 0 amount
          // for (var i =0; i < data.details.length; i++) {
          //   if (!data.details[i].amount) {
          //     data.details.splice(i, 1)
          //     i--
          //   }
          // }

          if (result.bill.fine_rules.length == 0) {
            var fine_rules = {
              value: 0,
              type: 'nominal',
              formula: 'flat',
              late_month_fine: {
                value: 0,
                type: 'nominal',
                formula: 'flat'
              }
            }
            data.fine_rules = fine_rules
          }
          this.data = data
          this.showModal()
        } else  if (results[0].response.data.error == true) {
          var errorMessage = results[0].response.data.messages
          swal(errorMessage, "", "error")
        }
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    resetTempCheckout: function () {
      this.tempCheckout.ids = []
      this.tempCheckout.type = ''
    },
    setTempCheckout: function (ids:any, type:any) {
      this.tempCheckout.ids = ids
      this.tempCheckout.type = type
    },
    resetConvenienceFee: function () {
      this.paymentMethod = true
      this.convenience_id = 0
      var payment = {
        amount: 0,
        convenience_fee: 0,
        merchant_fee: 0,
        total_discount: 0,
        ppn: 0,
        total_amount: 0
      }
      this.payment = payment
    },
    checkOutConvenience: function () {

      this.loadingBulk = true
      var data = {
        convenience_id: this.convenience_id,
        spp_user_bill_ids: this.tempCheckout.ids
      }
      Promise.all([
        customerFactory.checkoutOrder(data)
      ]).then(results => {
        if (results[0].error == false) {
          var us_order_id = results[0].data.order.us_order_id
          var order_id = results[0].data.order.id
          this.handlePayment(us_order_id, order_id)
        } else  if (results[0].response.data.error == true) {
          var errorMessage = results[0].response.data.messages
          swal(errorMessage, "", "error")
        }
        this.loadingBulk = false
      }).catch((e) => {
        // swal("Gagal melakukan request", "", "error")
        this.loadingBulk = false
        console.log(e)
      }).finally(() => {
        this.loadingBulk = false
      })
    },
    bulkCheckout: function(type:any) {
      this.resetTempCheckout()
      this.setTempCheckout(this.billIds, 'bulk')
      if (this.setting['ENABLE_CONVENIENCE_FEE'] == 'true' && type !== 'pay') {
        this.resetConvenienceFee()
        this.showModal('convenience')
      } else {
        this.loadingBulk = true
        swal({
          title: "Apakah anda yakin?",
          text: "Anda akan membayar tagihan terpilih.",
          icon: "warning",
          buttons: {
            cancel: true,
            confirm: true
          },
          dangerMode: true,
        })
        .then((isConfirm) => {
          if (isConfirm) {
            var data = {
              convenience_id: 0,
              spp_user_bill_ids: this.tempCheckout.ids
            }
            Promise.all([
              customerFactory.checkoutOrder(data)
            ]).then(results => {
              if (results[0]) {
                var us_order_id = results[0].data.order.us_order_id
                var order_id = results[0].data.order.id
                this.handlePayment(us_order_id, order_id)
              }
            })
          } else {
            this.loadingBulk = false
          }
        })
        .catch(() => {
          this.loadingBulk = false
        }).finally(() => {
          this.loadingBulk = false
        })
      }
    },
    oneYearCheckout: function(type:any) {
      this.checkAllBillBySetting()
      this.resetTempCheckout()
      this.setTempCheckout(this.billIds, 'bulk')

      if (this.tempCheckout.ids.length == 0) {
        swal("Perhatian",
          "Sudah dibayarkan semua atau masih ada invoice aktif",
          "warning")
        var order = this.findPendingOrder()
        if (order) {
          var invoice_path = `/user/invoice/${order.id}`
          router.push({ path: invoice_path })
        }
        return
      }

      if (this.setting['ENABLE_CONVENIENCE_FEE'] == 'true' && type !== 'pay') {
        this.resetConvenienceFee()
        this.showModal('convenience')
      } else {
        this.loadingBulk = true
        swal({
          title: "Apakah anda yakin?",
          text: "Anda akan membayar tagihan terpilih.",
          icon: "warning",
          buttons: {
            cancel: true,
            confirm: true
          },
          dangerMode: true,
        })
        .then((isConfirm) => {
          if (isConfirm) {
            var data = {
              convenience_id: 0,
              spp_user_bill_ids: this.tempCheckout.ids
            }
            Promise.all([
              customerFactory.checkoutOrder(data)
            ]).then(results => {
              if (results[0].error == false) {
                var us_order_id = results[0].data.order.us_order_id
                var order_id = results[0].data.order.id
                this.handlePayment(us_order_id, order_id)
              } else  if (results[0].response.data.error == true) {
                var errorMessage = results[0].response.data.messages
                swal(errorMessage, "", "error")
              }
            }).catch((e) => {
              swal("Gagal melakukan request", "", "error")
              console.log(e)
            })
          } else {
            this.loadingYear = false
          }
        })
        .catch(() => {
          this.loadingYear = false
        }).finally(() => {
          this.loadingYear = false
        })
      }
    },
    checkout: function(id:any, order_id:any, type:any) {
      var ids : any = []
      ids.push(id)
      this.resetTempCheckout()
      this.setTempCheckout(ids, 'single')
      if (this.setting['ENABLE_CONVENIENCE_FEE'] == 'true' && type !== 'pay') {
        this.resetConvenienceFee()
        this.showModal('convenience')
      } else {
        if ((order_id != undefined && !this.isCanceled(order_id)) || !this.tempCheckout.ids) {
          this.hideModal('convenience')
          // router.push({path: '/user/invoice', query: {id: order_id[0]}})
          var invoice_path = `/user/invoice/${order_id[0]}`
          router.push({ path: invoice_path })
        } else {
          this.loading[id] = true
          var data = {
            convenience_id: 0,
            spp_user_bill_ids: this.tempCheckout.ids
          }
          Promise.all([
            customerFactory.checkoutOrder(data)
          ]).then(results => {
            if (results[0].error == false) {
              var us_order_id = results[0].data.order.us_order_id
              var order_id = results[0].data.order.id
              this.handlePayment(us_order_id, order_id)
            } else  if (results[0].error == true) {
              var errorMessage = results[0].messages
              swal(errorMessage, "", "error")
              this.loading[id] = false
            }
          }).catch((e) => {
            swal("Gagal melakukan request", "", "error")
            console.log(e)
            this.loading[id] = false
          }).finally(() => {
            this.loading[id] = false
          })
        }
      }
    },
    addSnapJS () {
      var uangsekolah = document.createElement('script')
      var instance = String(localStorage.getItem('instance'))
      uangsekolah.setAttribute('src', localStorage.getItem('base_url') + '/' + instance + '/us/snap.js')
      document.head.appendChild(uangsekolah)
    },
    handlePayment: function(us_order_id:any, order_id:any) {

      Promise.all([
        usFactory.getUSToken(us_order_id)
      ]).then(results => {
        var token_order_id = results[0].data.us_order.token
        var us_order = results[0].data.us_order
        var order = results[0].data.order
        var merchant = us_order.merchant

        if (merchant.type == 'midtrans') {
          if (typeof snap === 'undefined') {
            swal("Halaman Belum siap", "", "error")
            return
          }

          var invoice_path = `/user/invoice/${order_id}`

          snap.pay(token_order_id, {
            uiMode: "qr",
            gopayMode: "qr",
            onSuccess: function(){
              this.hideModal('convenience')
              // router.push({path: '/user/invoice', query: {id: order_id}})
              router.push({ path: invoice_path })
              return false
            },
            onPending: function(){
              this.hideModal('convenience')
              // router.push({path: '/user/invoice', query: {id: order_id}})
              router.push({ path: invoice_path })
              return false
            },
            onError: function(){
              return false
            },
            onClose: function(){
              this.hideModal('convenience')
              // router.push({path: '/user/invoice', query: {id: order_id}})
              router.push({ path: invoice_path })
              return false
            }
          })

        } else if (merchant.type == 'xendit') {
          this.xenditInvoice = us_order.link
          this.xenditModal.show()
        // } else if (merchant.type == 'bca' || merchant.type.includes('offline') || merchant.type == 'cimb') {
        } else {
          const bankNames = {
            bca: 'BCA',
            panin: 'Panin',
            cimb: 'CIMB',
            bcah2h: 'BCA',
            permata: 'Permata',
            ocbc: 'OCBC',
            mandiri: 'Mandiri',
            bni: 'BNI',
            bri: 'BRI'
          }

          this.bca.orderId = us_order.order_id
          this.bca.va_number = us_order.custom_field1
          if(merchant.type.includes('offline')) {
            this.bca.va_number_nobill = us_order.custom_field2
            this.bca.bank_name = bankNames[merchant.type.split('-')[0]]
          } else {
            this.bca.bank_name = bankNames[merchant.type]
          }
          this.bca.amount = us_order.total_amount
          this.bca.convenience_fee = order.convenience_fee
          this.bca.merchant_fee = order.merchant_fee
          this.bca.total_discount = order.total_discount
          this.bca.ppn = order.ppn
          this.bca.total_payment = order.total_payment
          this.hideModal('convenience')
          this.bcaModal.show()
        }
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      }).finally(() => {
        this.loadData()
        return false
      })
      return false
    },
    handleConvenienceFee: function (value:any) {
      this.convenience_id = value
      var param = {
        convenience_id: value,
        spp_user_bill_ids: this.tempCheckout.ids
      }
      Promise.resolve(customerFactory.calcConvenienceFee(param))
      .then(result => {
        var payment = {
          convenience_fee: result.data.convenience_fee,
          merchant_fee: result.data.merchant_fee,
          amount: result.data.total_amount,
          total_discount: '-' + result.data.total_discount,
          ppn: result.data.ppn,
          total_amount: result.data.total_payment
        }
        this.payment = payment
        this.paymentMethod = false
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
      return false
    },
    invoice: function(order_id:any) {
      if (order_id != undefined) {
        var invoice_path = `/user/invoice/${order_id}`
        router.push({ path: invoice_path })
        // router.push({path: '/user/invoice', query: {id: order_id[0]}})
      }
    },
    findPendingOrder() {
      for (var i in this.userOrders) {
        var order = this.userOrders[i]
        if (order.status == constants.ORDER_BEGIN || order.status == constants.ORDER_PENDING) {
          return order
        }
      }
      return null
    },
    loadData: function() {
      Promise.all([
        customerFactory.bill(this.param)
      ]).then(results => {
        var userData = results[0].data.user
        var user = {
          name: ('name' in userData) ? userData.name : '',
          class_name: ('class' in userData) ? ('name' in userData.class) ? userData.class.name : '' : '',
          parent_father: ('parents' in userData) ? ('father' in userData.parents) ? userData.parents.father : '' : '',
          parent_mother: ('parents' in userData) ? ('mother' in userData.parents) ? userData.parents.mother : '' : '',
          user_profile_no_hp: ('user_profile' in userData) ? ('no_hp' in userData.user_profile) ? userData.user_profile.no_hp : '' : '',
          student_profile_nis: ('student_profile' in userData) ? ('nis' in userData.student_profile) ? userData.student_profile.nis : '' : ''
        }
        this.user = user
        this.userBills = results[0].data.user_bills
        this.userBills.forEach((value:any) => {
          value.total_payment = value.total_amount + value.late_charge
          this.totalLateCharge += value.late_charge
          // if('spp_order_ids' in value) value.spp_order_ids.sort().reverse()
        })
        this.stats = results[0].data.stats
        this.statuses = results[0].data.statuses
        this.userOrders = results[0].data.user_orders
        // this.billCount = this.userOrders.length
        //loop user orders to get the bill count
        this.userOrders.forEach((order) => {
          if(order.status >= constants.ORDER_SUCCESS) {
            this.billCount++;
          }
        });

        var path = this.path + this.subdomain + '/student_photos/' + this.user.student_profile_nis + '.jpg'
        this.imagepath = path
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    loadDataMaster: function() {
      var setting_data = {
        title: 'ENABLE_CONVENIENCE_FEE'
      }
      var setting_data_2 = {
        title: 'UANGSEKOLAH_DISCOUNT_SETTING'
      }
      return Promise.all([
        masterDataFactory.master_datas(this.data_master),
        masterDataFactory.setting_data(setting_data),
        masterDataFactory.setting_data(setting_data_2)
      ]).then(results => {
        this.schoolYears = results[0].data.school_years
        this.months = results[0].data.months
        this.school = results[0].data.school
        this.convenienceFee = results[0].data.convenience_fee
        this.schoolYears.forEach((school_year:any) => {
          if (school_year.status == "Active") {
            this.param.school_year_id = school_year.school_year_id
            this.school_year_name = school_year.name
          }
        })
        var setting = results[1].data.setting
        var value = (setting != null) ? setting.value : ''
        var title = (setting != null) ? setting.title : ''
        this.setting[title] = value
        var setting = results[2].data.setting
        var value = (setting != null) ? setting.value : ''
        var title = (setting != null) ? setting.title : ''
        this.setting[title] = value
        this.loadData()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    showModal: function(type:any) {
      if (type == 'convenience') {
        this.myModalConvenience.show()
      } else {
        this.myModal.show()
      }
    },
    hideModal: function(type:any) {
      if (type == 'convenience') {
        this.myModalConvenience.hide()
      } else {
        this.myModal.hide()
      }
    },
    showImportedVA: function (spp_order_id) {
      Promise.resolve(
        customerFactory.detailOrder(spp_order_id)
      ).then(res => {
        this.order = res.data.order

        Promise.resolve(
          usFactory.getUSToken(this.order.us_order.id)
        ).then(res => {
          this.imported_us_order = res.data.us_order
          this.imported_order = res.data.order
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
        })
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })

      this.importedVAModal.show()
    },
    async copyValue(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        alert('Berhasil Copy');
      } catch($e) {
        alert('Gagal Copy');
      }
    },
    formatPrice(value) {
      let val = Math.round(value/1).toFixed(0).replace('.', ',')
      return 'IDR ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
  computed: {
    totalSelectedAmount: function () {
      var totalSelectedAmount = 0
      this.userBills.forEach((detail:any) => {
        if (this.billIds.indexOf(detail.id) != -1) {
          totalSelectedAmount += detail.total_payment
        }
      })

      this.promoTerm = "";

      if(this.sppPromoAnnuallyPaymentPercentage > 0 && totalSelectedAmount > 0) {
        var isDiscounted = false;
        //group by second part of the bills id
        let group = this.billIds.reduce((r, a) => {
          r[a.split('-')[1]] = [...r[a.split('-')[1]] || [], a];
          return r;
        }, {});
        //set isDiscounted to true if there is a group with more or equal to 12 bills
        Object.keys(group).forEach(key => {
          if(group[key].length >= 12) {
            isDiscounted = true;
          }
        });

        let str = this.toLocaleString(totalSelectedAmount);

        if(isDiscounted) {
          totalSelectedAmount = totalSelectedAmount - ((totalSelectedAmount - this.totalLateCharge) * this.sppPromoAnnuallyPaymentPercentage / 100);
        }
        
        let newStr = this.toLocaleString(totalSelectedAmount);

        if(str == newStr) return str;

        this.promoTerm = `Potongan harga ${this.sppPromoAnnuallyPaymentPercentage}% untuk pembayaran 1 tahun`;
        if(this.totalLateCharge > 0) {
          this.promoTerm += ` (diluar denda)`;
        }
        //buat harga coret
        return `${newStr} <span style="text-decoration: line-through; font-size: 12pt; color: #DC3545;">${str}</span>`;

      }
      
      return this.toLocaleString(totalSelectedAmount)
    },
    config() {
      return {
        currency: 'IDR',
        precision: 0,
        distractionFree: false,
        valueAsInteger: true,
      }
    }
  },
  async created() {
    Promise.resolve(setupFactory.getFeatureConfigs({feature_configs: ['disable_spp_bill_checkboxes', 'show_US_import_VA', 'spp_promo_annually_payment_percentage']})).then(
      (res) => {
        if('disable_spp_bill_checkboxes' in res.data.feature_configs){
          this.disableCheckboxes = res.data.feature_configs.disable_spp_bill_checkboxes
        }
        this.useImportVA = ('show_US_import_VA' in res.data.feature_configs && res.data.feature_configs.show_US_import_VA && res.data.feature_configs.show_US_import_VA == 'true')
        if('spp_promo_annually_payment_percentage' in res.data.feature_configs){
          this.sppPromoAnnuallyPaymentPercentage = parseInt(res.data.feature_configs.spp_promo_annually_payment_percentage)
        }
      }
    )
  },
  async mounted () {
    await this.loadDataMaster()
    await this.addSnapJS()

    var myModal = document.getElementById('billDetailModal')
    if (myModal != null) {
      this.myModal = new bootstrap.Modal(myModal)
    }
    var xenditModal = document.getElementById('xenditModal')
    if (xenditModal != null) {
      this.xenditModal = new bootstrap.Modal(xenditModal)
    }
    var bcaModal = document.getElementById('bcaModal')
    if (bcaModal != null) {
      this.bcaModal = new bootstrap.Modal(bcaModal)
    }
    var myModalConvenience = document.getElementById('convenienceFeeModal')
    if (myModalConvenience != null) {
      this.myModalConvenience = new bootstrap.Modal(myModalConvenience)
    }
    var importedVAModal = document.getElementById('importedVAModal')
    if (importedVAModal != null) {
      this.importedVAModal = new bootstrap.Modal(importedVAModal)
    }

    if (this.$route.query.repay_user_bill_ids) {
      this.billIds = this.$route.query.repay_user_bill_ids
      this.bulkCheckout()
    }
  }

})

export default class UserBill extends Vue {}
